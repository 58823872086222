/**
 * @generated SignedSource<<60109c7252f5ee1a4e3d3d69ba4c8cbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CampaignType = "CONTENT_CREATION" | "INFLUENCER" | "LONG_TERM" | "PAYOUT" | "PRODUCT_SEEDING";
export type CreatorBadge = "HIGHLY_RESPONSIVE" | "HIGH_POTENTIAL" | "TOP_RATED" | "UGC_EXPERT";
export type CreatorType = "CONTENT" | "INSTAGRAM" | "TIKTOK" | "YOUTUBE";
export type FileType = "IMAGE" | "OTHER" | "VIDEO";
export type ProjectArchivationSide = "ADVERTISER" | "CONTRACTOR";
import { FragmentRefs } from "relay-runtime";
export type BloggerInfo_project$data = {
  readonly archivation: {
    readonly id: string;
    readonly side: ProjectArchivationSide;
  } | null | undefined;
  readonly archivationRequest: {
    readonly acceptance: {
      readonly id: string;
    } | null | undefined;
    readonly rejection: {
      readonly id: string;
    } | null | undefined;
    readonly side: ProjectArchivationSide;
  } | null | undefined;
  readonly blockedCreator: boolean | null | undefined;
  readonly campaign: {
    readonly id: string;
    readonly organization: {
      readonly id: string;
    } | null | undefined;
    readonly type: CampaignType | null | undefined;
  };
  readonly completion: {
    readonly id: string;
  } | null | undefined;
  readonly creator: {
    readonly channel?: {
      readonly id: string | null | undefined;
      readonly name: string | null | undefined;
      readonly subscribers: number | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly medias?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly instagramUrl: string;
          readonly shortcode: string;
          readonly standardResUrl: string;
          readonly thumbnailUrl: string;
          readonly videoUrl: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly portfolioItems: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly file: {
            readonly id: string;
            readonly thumbnailUrl: string;
            readonly transformations?: {
              readonly autoUrl?: string;
              readonly collageThumbnailUrl: string;
            } | null | undefined;
            readonly type: FileType;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
    readonly profile: {
      readonly badge: CreatorBadge | null | undefined;
      readonly countries: ReadonlyArray<{
        readonly name: string;
      }>;
      readonly profilePictureFile: {
        readonly transformations?: {
          readonly collageThumbnailUrl: string;
        } | null | undefined;
        readonly url: string | null | undefined;
      } | null | undefined;
      readonly videoResumeFile: {
        readonly secureUrl: string;
        readonly thumbnailUrl: string;
        readonly transformations?: {
          readonly autoUrl: string;
          readonly collageThumbnailUrl: string;
        } | null | undefined;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"useCreatorAmazonStorelink_creator">;
    } | null | undefined;
    readonly tiktokUser?: {
      readonly engagementRate: number | null | undefined;
      readonly followedByCount: number | null | undefined;
    } | null | undefined;
    readonly type: CreatorType;
    readonly user?: {
      readonly engagementRate: number | null | undefined;
      readonly estimatedLikes: number | null | undefined;
      readonly followedByCount: number | null | undefined;
      readonly fullName: string | null | undefined;
    } | null | undefined;
    readonly username: string;
  } | null | undefined;
  readonly favorite: boolean;
  readonly id: string;
  readonly launch: {
    readonly id: string;
  } | null | undefined;
  readonly shortlisted: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"CreatorActions_project">;
  readonly " $fragmentType": "BloggerInfo_project";
};
export type BloggerInfo_project$key = {
  readonly " $data"?: BloggerInfo_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"BloggerInfo_project">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "side",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "collageThumbnailUrl",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "autoUrl",
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Video",
  "abstractKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageTransformation",
      "kind": "LinkedField",
      "name": "transformations",
      "plural": false,
      "selections": [
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "followedByCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "engagementRate",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BloggerInfo_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favorite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blockedCreator",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreatorActions_project"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectLaunch",
      "kind": "LinkedField",
      "name": "launch",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectCompletion",
      "kind": "LinkedField",
      "name": "completion",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivation",
      "kind": "LinkedField",
      "name": "archivation",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectArchivationRequest",
      "kind": "LinkedField",
      "name": "archivationRequest",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectArchivationRequestAcceptance",
          "kind": "LinkedField",
          "name": "acceptance",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectArchivationRequestRejection",
          "kind": "LinkedField",
          "name": "rejection",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortlisted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Campaign",
      "kind": "LinkedField",
      "name": "campaign",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CreatorProfile",
          "kind": "LinkedField",
          "name": "profile",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "badge",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Country",
              "kind": "LinkedField",
              "name": "countries",
              "plural": true,
              "selections": [
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "videoResumeFile",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "secureUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "profilePictureFile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                (v8/*: any*/)
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useCreatorAmazonStorelink_creator"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 10
            }
          ],
          "concreteType": "PortfolioItemConnection",
          "kind": "LinkedField",
          "name": "portfolioItems",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PortfolioItemEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PortfolioItem",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "file",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/),
                        (v5/*: any*/),
                        (v8/*: any*/),
                        (v7/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "portfolioItems(first:10)"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "YoutubeChannel",
              "kind": "LinkedField",
              "name": "channel",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subscribers",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "YoutubeCreator",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "tiktokUser",
              "args": null,
              "concreteType": "TiktokUser",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v9/*: any*/),
                (v10/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "TiktokCreator",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstagramUser",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullName",
                  "storageKey": null
                },
                (v9/*: any*/),
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "estimatedLikes",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 8
                }
              ],
              "concreteType": "InstagramMediaConnection",
              "kind": "LinkedField",
              "name": "medias",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "InstagramMediaEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "InstagramMedia",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "videoUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "shortcode",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "instagramUrl",
                          "storageKey": null
                        },
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "standardResUrl",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "medias(first:8)"
            }
          ],
          "type": "InstagramCreator",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};
})();

(node as any).hash = "238b27e1c7d88d2005d9ffbdb6c345cb";

export default node;
